<template>
    <v-row class="top-header-flex" style="justify-content:center;padding:40px">

        <a href="https://acteurspourlaplanete.fntp.fr/">
          <v-img
                  id="fntp-image"
                  width="155"
                  src="/icons/svg/logo-FNTP.svg"
          >
          </v-img>
        </a>
    </v-row>

 </template>

<script>

  export default {
    name: 'DashboardCoreAppBar',

    data: () => ({
    }),

  }
</script>